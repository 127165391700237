import React from "react";
import "./scss/style.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Login = React.lazy(() => import("./views/client/login/Login"));
const HomeBroker = React.lazy(() =>
  import("./views/client/home-broker/HomeBroker")
);
const Bolsa = React.lazy(() =>
  import("./views/client/home-broker/Bolsa/Bolsa")
);
const ReturnOfCheckout = React.lazy(() =>
  import("./views/client/ReturnOfCheckout")
);
const ResendUsersEmail = React.lazy(() =>
  import("./views/client/ResendUsersEmail")
);
const RenewPlan = React.lazy(() => import("./views/client/RenewPlan"));
const AppAdmin = React.lazy(() => import("./AppAdmin"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));

const routes = [
  { path: "/", exact: true, component: HomeBroker },
  { path: "/bolsa", exact: true, component: Bolsa },
  { path: "/admin", component: AppAdmin },
  { path: "/login", component: Login },
  { path: "/return-of-checkout", component: ReturnOfCheckout },
  { path: "/resend-email", component: ResendUsersEmail },
  { path: "/renew-plan", component: RenewPlan },
  { path: "*", component: Page404 },
];

const App = () => (
  <BrowserRouter>
    <React.Suspense fallback={loading}>
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          );
        })}
      </Switch>
    </React.Suspense>
  </BrowserRouter>
);

export default App;
